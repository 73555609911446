.sidebar {
  padding: 5%;
}

.sidebar img {
  width: 40%;
  cursor: pointer;
  margin-left: 10px;
}

.sidebar .dropdown {
  border-radius: 10px;
  width: 50%;
  box-shadow: 0px 2px 4px 1px rgba(0,0,0,0.1)
}

.sidebar .dropdown p {
  width: 100%;
}

.sidebar p {
  border-radius: 10px;
  width: 50%;
  padding: 10px 0px 10px 10px;
}

.sidebar p:hover {
  background-color: rgb(247, 247, 247);
}