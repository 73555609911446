.home {
  margin-top: 5%;
}

.home .post {
  margin-bottom: 2%;
  padding: 0 22% 0 22%;
  margin: 0 0 2% 0;
}

.home .post p {
  width: 100%;
  font-size: 15px;
}

.home .post .destination {
  font-size: 12px;
  margin-bottom: 2%;
}

.home .post .date {
  font-size: 12px;
  color: rgb(51, 51, 51);
}

.home .post .title {
  margin-top: 2%;
}

.home .square-img {
  position: relative;
}

.home .square-img::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.home .post img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

.home .post hr {
  margin: 2% 0 2% 0;
}

@media (max-width: 1000px) {
  .home {
    margin-top: 0%;
  }

  .home .post {
    margin-bottom: 2%;
    padding: 0 0% 0 0%;
    margin: 2% 0 2% 0;
  }

  .home .post p, .post h3, .post h4 {
    padding: 0 2% 0 2%;
  }

  .home .post hr {
    margin: 4% 0 4% 0;
  }
}

.right-info {
  margin-top: 5%
}

/* Add this new class to your CSS file */
.home .square-img {
  position: relative;
}

.home .square-img::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.home .post img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

/* New CSS for the click overlay */
.home .square-img .click-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 20px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.home .square-img:hover .click-overlay {
  opacity: 1;
}