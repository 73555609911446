.bottom-nav {
  display: none;
}

.bottom-nav .nav-items {
  display: flex;
  justify-content: space-between;
}

.bottom-nav .nav-items p {
  margin: 0;
  font-size: 20px;
}

.bottom-nav .nav-items .dropdown {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 90%;
}

.bottom-nav .nav-items .dropdown a {
  display: block;
  padding: 10px;
  text-align: center;
}


@media (max-width: 1000px) {
  .bottom-nav {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff; /* Change this to your desired background color */
    padding: 10px; /* Adjust as needed */
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Optional: add a shadow for better visibility */
    z-index: 1000; /* Ensure it appears above other content */
  }
}