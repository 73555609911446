.itinerary {
  margin-top: 8%;
}

.itinerary .square-img {
  position: relative;
}

.itinerary .square-img::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.itinerary img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

.itinerary-column-description-left {
  float: left;
  width: 20%;
}

.itinerary-column-description-right {
  float: left;
  width: 75%;
  margin-left: 5%;
}

.itinerary .add-a-day {
  float: left;
  width: 100%;
  margin-top: 5%;
  text-align: center;
}

.itinerary .days-section {
  float: left;
  width: 100%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.itinerary .column-day {
  float: left;
  width: 20%;
  margin-bottom: 5%;
}

.itinerary .column-map {
  float: left;
  width: 75%;
  margin-left: 5%;
  margin-bottom: 5%;
}

@media (max-width: 1000px) {
  .itinerary {
    margin-top: 0%;
  }

  .itinerary-column-description-left {
    width: 100%;
  }

  .itinerary-column-description-right {
    float: left;
    width: 96%;
    margin: 2% 4% 2% 4%;
  }

  .itinerary .days-section {
    width: 96%;
    margin: 4% 4% 2% 4%;
  }

  .itinerary .column-day {
    width: 100%;
    margin-bottom: 5%;
  }

  .itinerary .column-map {
    width: 96%;
    margin: 0 0 10% 0;
  }

  .itinerary hr {
    margin: 0 4% 0 0;
  }
}

.share-section {
  margin-top: 5%;
  text-align: center;
}

.share-section h3 {
  margin-bottom: 10px;
}

.share-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.share-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  color: white;
}

.fb-share {
  background-color: #3b5998;
}

.x-share {
  background-color: #1da1f2;
}

.ig-share {
  background: linear-gradient(45deg, #f09433, #e6683c, #dc2743, #cc2366, #bc1888);
}

.share-button:hover {
  opacity: 0.8;
}

@media (max-width: 1000px) {
  .share-section {
    margin: 0 5% 0 5%;
  }
}