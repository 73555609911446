.newdate-left-column {
  float: left;
  width: 45%;
  margin-right: 5%;
  margin-bottom: 5%;
}

.newdate-left-column input, .newdate-left-column select {
  width: 100%;
  margin: 0%;
}

.newdate-right-column {
  float: left;
  width: 50%;
  margin-bottom: 5%;
}

@media (max-width: 1000px) {
  .newdate-left-column {
    float: left;
    width: 90%;
    margin: 0 5% 5% 5%;
  }

  .newdate-right-column {
    float: left;
    width: 90%;
    margin: 0 5% 5% 5%;
  }
}