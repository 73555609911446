.top-bar {
  display: none;
}

@media (max-width: 1000px) {
  .top-bar {
    display: block;
  }

  .top-bar img {
    width: 26%;
    margin: 5% 37% 2% 37%;
  }
}