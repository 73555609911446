.privacy-policy .content {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.privacy-policy h1, .privacy-policy h2, .privacy-policy h3 {
  margin-top: 20px;
}

.privacy-policy p, .privacy-policy ul {
  margin-top: 10px;
  line-height: 1.6;
}

.privacy-policy ul {
  padding-left: 20px;
  list-style-type: disc;
}
