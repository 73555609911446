.profile {
  margin-top: 8%;
}

.profile .info-container {
  display: flex;
  align-items: center; /* Align items vertically */
}

.profile .image-container {
  width: 180px; /* Adjust width as needed */
  height: 180px; /* Adjust height as needed */
  overflow: hidden; /* Clip the corners of the image */
  border-radius: 50%; /* Ensure rounded corners */
  margin-right: 20px; /* Add spacing between image and username */
  margin-left: 10%;
}

.profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile .username {
  flex-grow: 1; 
  margin-left: 5%;
}

.profile .info {
  display: flex;
}

.profile .info > div {
  margin-right: 20px; 
}

.profile .info > div:last-child {
  margin-right: 0;
}

.profile .column-itinerary {
  float: left;
  width: 33.33%;
  padding: 1%;
}

@media (max-width: 1000px) {
  .profile .info-container {
    width: 90%;
    margin: 0 5% 0 5%;
  }

  .profile .image-container {
    width: 100px; /* Adjust width as needed */
    height: 100px; /* Adjust height as needed */
    overflow: hidden; /* Clip the corners of the image */
    border-radius: 50%; /* Ensure rounded corners */
    margin-right: 20px; /* Add spacing between image and username */
    margin-left: 0%;
  }

  .profile .column-itinerary {
    width: 50%;
    padding: 0.1%;
  }
}

.profile .square-img {
  position: relative;
}

.profile .square-img::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.profile .column-itinerary img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

.profile .image-text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the background color and opacity */
  color: white; /* Adjust the text color */
  padding: 5px; /* Adjust padding as needed */
  box-sizing: border-box;
  text-align: center;
}

.profile .price-tag {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ff6347; /* Adjust background color as needed */
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
}

.profile .visibility-tag {
  position: absolute;
  top: 10px;
  left: 10px; /* Positioned on the other side of the price tag */
  background-color: #62acf5; /* Blue background */
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
}