.signup {
  text-align: center;
  margin-top: 5%;
}

.signup input, select, textarea {
  width: 50%;
  margin: 0px 25% 0px 25%;
}

.signup img {
  width: 20%;
}