.column-layout-left {
  position: fixed;
  top: 4%;
  left: 2%; 
  width: 20%; 
}

@media (max-width: 1000px) {
  .column-layout-left {
    display: none;
  }
}

.column-layout-middle {
  margin-left: 20%; 
  width: 60%; 
  float: left;
}

@media (max-width: 1000px) {
  .column-layout-middle {
    margin-left: 0%;
    margin-bottom: 10%; 
    width: 100%;
  }
}

.column-layout-right {
  width: 20%;
  float: left;
}

@media (max-width: 1000px) {
  .column-layout-right {
    display: none;
    margin-left: 0%;
    width: 0%;
  }
}