/* Reset default browser styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Global typography */
body {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

/* Clear link style */
a {
  text-decoration: none;
  color: black;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Inputs */
input, select, textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 0.5px solid black;
  resize: none;
}

/* Buttons */
button {
  color: white;
  background-color: #62acf5;
  border: 0px;
  border-radius: 10px;
  cursor: pointer;
  padding: 10px 20px 10px 20px;
  font-weight: bold;
}

.button-red {
  background-color: red;
}

.review-star {
  color: #FFD700
}